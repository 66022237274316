<template>
<div class="insurance-dividends">
    <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">{{ $t('insurance.dividends.title') }}</span>
    </div>
    <div class="list">
        <div class="box" v-for="x,i in data" :key="'r'+i">
            <div>#{{x.insurance_id}}</div>
            <div class="flex">
                <div class="time color-gray">{{x.created_at}}</div>
                <div class="amount color-primary">{{x.amount/1000000}}USDT</div>
            </div>
        </div>
        <div v-if="showLoadingMore"
            :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
        >
            <a-spin v-if="loading" />
            <a-button v-else type="dashed" ghost @click="getData()">
                {{$t('common.more')}}
            </a-button>
        </div>
    </div>
</div>
  
</template>
<script>
export default {
  name: "InsuranceDividends",
  data() {
    return {
      loading: true,
      showLoadingMore: true,
      page:1,
      pageSize: 10,
      data: [],
      mode:this.$route.params.mode || 1,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(reset = 0){
        if(reset==1){
            this.pageSize = 5;
            this.page = 1;
            this.data = [];
            this.showLoadingMore = true;
        }
        this.loading = true;
        this.$http.call(this.$http.api.insuranceDividends,{
            data:{mode:this.mode,page:this.page,size:this.pageSize}
        }).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                this.page++;
                this.data = this.data.concat(resp.data);
                if(resp.data.length<this.pageSize){
                    this.showLoadingMore = false;
                }
            }else{
                this.$message.error(resp.data);
            } 
            
        },res=>{
            console.log('something goes wrong...');
            console.log(res.response);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        }).then(()=>{
            
        });
    }
  },
};
</script>
<style lang="less" scoped>
.insurance-dividends{
    padding: 70px 15px 15px;
    width: 100%;
    .list{
        .box{
            padding: 15px;
            border-radius: 7px;
            margin-bottom: 15px;
            text-align: left;
            color: @gray;
            .amount{
                font-weight: bold;
            }
        }
    }
}
</style>
